<template>
  <PageWrapper>
    <div class="w-full">
      <Header v-slot="{ _headerClass, _subheaderClass }">
        <h1 :class="_headerClass">Expenses</h1>
        <p :class="_subheaderClass">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eligendi
          fugit enim facere aliquid, vitae sapiente distinctio voluptatem ut.
          Quae, consequuntur!
        </p>
      </Header>
      <Table>
        <template #header="{ _class }">
          <div class="grid-cols-5" :class="_class">
            <div>Owner</div>
            <div>Category</div>
            <div>Type</div>
            <div>Est. Yearly Cost</div>
            <div>Description</div>
          </div>
        </template>
        <template #row="{ _outerClass, _innerClass, _buttonClass }">
          <div
            :class="_outerClass"
            v-for="expense in expenses"
            :key="expense.id"
          >
            <div class="grid-cols-5" :class="_innerClass">
              <div>{{ expense.owner }}</div>
              <div>{{ expense.category }}</div>
              <div>{{ expense.type }}</div>
              <div>{{ expense.total }}</div>
              <div>
                {{ expense.description }}
              </div>
            </div>
            <div :class="_buttonClass">
              <EditButton />
              <DeleteButton />
            </div>
          </div>
        </template>
        <template
          #col="{
            _outerClass,
            _headerOuterClass,
            _headerClass,
            _btnClass,
            _innerClass,
            _cellOuterClass,
            _cellHeaderClass,
            _cellBodyClass,
          }"
        >
          <div
            :class="_outerClass"
            v-for="expense in expenses"
            :key="expense.id"
          >
            <div :class="_headerOuterClass">
              <h6 :class="_headerClass">{{ expense.type }}</h6>
              <div :class="_btnClass">
                <EditButton />
                <DeleteButton />
              </div>
            </div>
            <div :class="_innerClass">
              <div :class="_cellOuterClass" class="border-t">
                <div :class="_cellHeaderClass">Owner</div>
                <div :class="_cellBodyClass">
                  {{ expense.owner }}
                </div>
              </div>
              <div :class="_cellOuterClass">
                <div :class="_cellHeaderClass">Category</div>
                <div :class="_cellBodyClass">
                  {{ expense.category }}
                </div>
              </div>
              <div :class="_cellOuterClass">
                <div :class="_cellHeaderClass">Type</div>
                <div :class="_cellBodyClass">
                  {{ expense.type }}
                </div>
              </div>
              <div :class="_cellOuterClass">
                <div :class="_cellHeaderClass">Est. Yearly Cost</div>
                <div :class="_cellBodyClass">
                  {{ expense.total }}
                </div>
              </div>
              <div :class="_cellOuterClass">
                <div :class="_cellHeaderClass">Description</div>
                <div :class="_cellBodyClass">
                  {{ expense.description }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </Table>

      <!-- Add Expense Button -->
      <button
        @click="displayAddExpenseModal"
        class="mb-4 px-1 text-blue-500 font-medium text-sm flex justify-center items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="ai ai-CreditCard mr-1"
        >
          <rect x="2" y="4" width="20" height="16" rx="2" />
          <path
            d="M12 10.016A2.794 2.794 0 0 0 9.857 9C8.28 9 7 10.343 7 12s1.28 3 2.857 3c.854 0 1.62-.393 2.143-1.016M17 12c0 1.657-1.28 3-2.857 3-1.578 0-2.857-1.343-2.857-3s1.279-3 2.857-3C15.72 9 17 10.343 17 12z"
          /></svg
        >Add Expense
      </button>

      <AddExpense
        :showModal="showAddExpenseModal"
        @hideModal="hideAddExpenseModal"
      />

      <div class="w-full flex justify-end items-center">
        <router-link
          :to="{ name: 'assets' }"
          class="btn-secondary py-2 w-24 rounded text-sm mx-2"
        >
          Back</router-link
        ><router-link
          :to="{ name: 'forms' }"
          class="btn-primary text-sm py-2 w-24 rounded"
          >Continue</router-link
        >
      </div>
    </div>
  </PageWrapper>
</template>

<script>
import useBenefitRecord from "../../composables/useBenefitRecord";
import PageWrapper from "../../components/home/layout/PageWrapper.vue";
import Table from "../../components/home/ui/Table.vue";
import EditButton from "../../components/home/ui/EditButton.vue";
import DeleteButton from "../../components/home/ui/DeleteButton.vue";
import Header from "../../components/home/ui/Header.vue";
import AddExpense from "../../components/home/ui/AddExpense.vue";
import { reactive, toRefs } from "@vue/reactivity";
export default {
  components: {
    PageWrapper,
    Table,
    EditButton,
    DeleteButton,
    Header,
    AddExpense,
  },
  setup() {
    const { expenses } = useBenefitRecord();
    const state = reactive({
      showAddExpenseModal: false,
    });

    const displayAddExpenseModal = () => {
      state.showAddExpenseModal = true;
    };

    const hideAddExpenseModal = () => {
      state.showAddExpenseModal = false;
    };
    return {
      expenses,
      ...toRefs(state),
      displayAddExpenseModal,
      hideAddExpenseModal,
    };
  },
};
</script>
